@tailwind base;

@layer base {
  img {
    @apply inline;
  }
}
@tailwind components;
@tailwind utilities;

